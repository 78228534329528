<template>
    <div class="container my-5">
        <h2 class="text-center mb-5">About Us</h2>
        <div class="row g-5">
            <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                <div class="col-6 col-md-5 col-lg-4 col-xl-3">
                    <img class="w-100 rounded-3" src="https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fprofiles%2Fprofile01.png?alt=media&token=3ea39fd8-a4d7-4605-b9a7-872904d9955f" alt="">
                </div>
                <div class="col-12 my-4">
                    <div class="text-center">
                        <h5 class="fw-bold text-darkblue">PUSSADEE JATURONGSAROCH</h5>
                        <span>Partner, CPA</span>
                    </div>
                    <div class="mt-5">
                        <p>
                            Audit/ RMS / Accounting Projects<br/>
                            More than 25 years of experience in the accounting profession.
                        </p>
                        <ul class="my-4">
                            <li>Certified Public Accountant Thailand (CPA)</li>
                            <li>Master Degree in Accounting Information System at Chulalongkorn University</li>
                            <li>BA in Accounting from Kasetsart University 1st class honors</li>
                        </ul>
                        <p>
                            Pussadee has over 25 years experience as an auditor, spending more than 8 years of her career with Arthur Andersen. She has served a variety of clients in various sector including retail & consumer products, industrial products, agriculture businesses, real estate, health care, state enterprises and non-profit organizations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column align-items-center">
                <div class="col-6 col-md-5 col-lg-4 col-xl-3">
                    <img class="w-100 rounded-3" src="https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fprofiles%2Fprofile02.png?alt=media&token=f8902bca-513c-4a15-857b-e6db4e391b64" alt="">
                </div>
                <div class="col-12 my-4">
                    <div class="text-center">
                        <h5 class="fw-bold text-darkblue">CHANPEN SUTEERAPONGPUN</h5>
                        <span>Partner, CPA</span>
                    </div>
                    <div class="mt-5">
                        <p>
                            More than 25 years’ experience providing advisory services.
                        </p>
                        <h5 class="text-blue">
                            Professional Qualifications
                        </h5>
                        <ul class="my-4">
                            <li>Certified Public Accountant Thailand (CPA)</li>
                            <li>Bachelor of Accountancy from Thammasat University</li>
                        </ul>
                        <p>
                            Strong experience in Business Advisory Services and Thai Taxation Advisory Services. Financial Transformation and Process Simplification
                        </p>
                        <p>
                            Managed a portfolio of clients ranging from multinationals to small and medium growing enterprises, operating in a diverse range of industries, including manufacturing, trading and distribution, electronics and technology, retail, construction and property development.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            employees: [
                {
                    firstname: "PUSSADEE",
                    lastname: "JATURONGSAROCH",
                    img: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fprofiles%2Fprofile01.png?alt=media&token=3ea39fd8-a4d7-4605-b9a7-872904d9955f",
                },
                {
                    img: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fprofiles%2Fprofile02.png?alt=media&token=f8902bca-513c-4a15-857b-e6db4e391b64",
                },
            ]
        }
    }
}
</script>